import * as React from "react";
import { DestinationBestTimeToGoInformation } from "@shared-ui/retail-destination-content-destination-guide";
import { BestTimeToGoInformationProps } from "./typings";
import { UitkCardBackgroundThemes, UitkCardBackgroundTheme } from "@egds/react-core/cards";
import { useMediaQueries, defaultViewSizes } from "@shared-ui/viewport-context";
import { UitkCardBackgroundThemesMapper } from "./utils/uitk-card-background-themes-map";

export const BestTimeToGoInformation: React.FC<BestTimeToGoInformationProps> = ({ context, templateComponent }) => {
  const viewPortSize = useMediaQueries({ viewSizes: defaultViewSizes });
  const placeId = context?.searchContext?.location?.id;
  const {
    skipSsr = false,
    showTabs = true,
    showSkeleton = true,
    priceTabMinWidth,
    priceTabHeightSmall,
    priceTabHeightLarge,
    isInteractive = true,
    isHidden = false,
    backgroundThemeOverride,
    weatherTabCustomHeight,
  } = templateComponent?.config || {};
  const isBrandExpedia = context.site.brand === "expedia";

  const priceTabHeight = viewPortSize === defaultViewSizes.SMALL ? priceTabHeightSmall : priceTabHeightLarge;

  if (!placeId) {
    return null;
  }

  const data = {
    placeId: String(placeId),
  };

  const priceTabOptions = {
    priceTabMinWidth,
    priceTabHeight: priceTabHeight ? `${priceTabHeight}px` : undefined,
    isInteractive,
  };

  let backgroundTheme: UitkCardBackgroundTheme = isBrandExpedia
    ? UitkCardBackgroundThemes.HIGHLIGHT
    : UitkCardBackgroundThemes.SECONDARY;
  if (backgroundThemeOverride && UitkCardBackgroundThemesMapper[backgroundThemeOverride]) {
    backgroundTheme = UitkCardBackgroundThemesMapper[backgroundThemeOverride];
  }

  return (
    <DestinationBestTimeToGoInformation
      inputs={data}
      skipSsr={skipSsr}
      priceTabOptions={priceTabOptions}
      showSkeleton={showSkeleton}
      showTabs={showTabs}
      isHidden={isHidden}
      backgroundThemeOverride={backgroundTheme}
      weatherTabCustomHeight={weatherTabCustomHeight}
    />
  );
};

export default BestTimeToGoInformation;
