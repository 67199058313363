import { UitkCardBackgroundTheme, UitkCardBackgroundThemes } from "@egds/react-core/cards";

export const UitkCardBackgroundThemesMapper: Record<string, UitkCardBackgroundTheme> = {
  primary: UitkCardBackgroundThemes.PRIMARY,
  secondary: UitkCardBackgroundThemes.SECONDARY,
  highlight: UitkCardBackgroundThemes.HIGHLIGHT,
  "global-loyalty-standard": UitkCardBackgroundThemes.GLOBAL_LOYALTY_STANDARD,
  "global-loyalty-low": UitkCardBackgroundThemes.GLOBAL_LOYALTY_LOW,
  "global-loyalty-middle": UitkCardBackgroundThemes.GLOBAL_LOYALTY_MIDDLE,
  "global-loyalty-high": UitkCardBackgroundThemes.GLOBAL_LOYALTY_HIGH,
  "global-loyalty-extra-high": UitkCardBackgroundThemes.GLOBAL_LOYALTY_EXTRAHIGH,
};
